import logo from './logo-55.jpeg';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <marquee vspace="10"><h2>Coming soon !!! </h2> </marquee>
            </header>
            <body>
            <div className={'App-body'}>
                <p>
                    Whatsscore's Coach55 web app exclusively for coaching academies. Now you can seamlessly manage
                    all your students, payments, batches and assessments in one place.
                    <br/><br/>
                    Are you struggling with:
                    <li>Missed student payments </li>
                    <li>  Keeping track of schedules and batches </li>
                    <li>  Showcasing student performance </li>
                    <li>   Retaining students </li>
                    <br/>
                    Let Coach55 app be your trusted partner, while you focus on doing what you love most - coaching!
                    Here is our inaugural offer you cannot refuse - First 50 coaching academies get the Coach55 app
                    for FREE for the first year! <br/><br/>
                    <a
                        className="App-link"
                        href=" https://forms.gle/8JC2iqCMwzRaKv8Q6"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Register now to claim this offer
                    </a>
                </p>
            </div>

            </body>
        </div>
    );
}

export default App;
